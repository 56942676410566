import {
  distinctUntilChanged, filter, flatMap, map, pluck, skipUntil, take,
} from 'rxjs/operators';
import { concat, of, } from 'rxjs';
import { combineEpics, ofType, } from 'redux-observable';
import {
  equals, isEmpty, length, map as rMap, pipe, propOr, sum, values,
} from 'ramda';
import { actions as bootstrapActions, selectors, } from '@ezugi/bootstrap';
import { NO_MORE_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants';

import totalsActions from '../../actions/totals';
import myBetsActions from '../../actions/myBets';

import * as BET_TYPES from '../../../constants/betTypes';
import { getTotalBet, } from './utils';
import {
  currentBetsSelector,
  flushBetSelector,
  onePairBetSelector,
  playerABetSelector,
  playerBBetSelector,
  straightBetSelector,
  straightFlushBetSelector,
  threeOfAKindBetSelector,
  totalFlushSelector,
  totalOnePairASelector,
  totalPlayerASelector,
  totalPlayerBSelector,
  totalStraightFlushSelector,
  totalStraightSelector,
  totalThreeOfAKindSelector,
} from '../../selectors/bets';
import { hasReachedCardsLimitSelector, } from '../../selectors/cards';
import { createPlaceBetRequestPayload, } from './utils/request';
import {
  validateBet, validateBetUndo, validateDouble, validateRebet,
} from './utils/validations';

const { totals, } = totalsActions;
const { roundActions, betActions, } = bootstrapActions;
const { round, } = roundActions;
const { bet, totalBet, history, } = betActions;
const { selectedChipSelector, } = selectors;

const { TOTALS, } = BET_TYPES;

const placeBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.request),
  pluck('payload'),
  map((payload) => ({
    ...payload,
    value: selectedChipSelector(state$.value).value,
  })),
  map((bets) => validateBet(bets, state$.value)),
  flatMap(({ valid, bet: _bet, ...result }) => {
    const actions = [ ...(valid && _bet && !isEmpty(_bet) ? [ of(bet.set(_bet)), ] : []), ];

    actions.push(...rMap(of, result.actions));

    return concat(...actions);
  })
);

const betResetEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === PLACE_YOUR_BETS && hasReachedCardsLimitSelector(state$.value)),
  flatMap(() => concat(of(bet.reset()), of(history.reset())))
);

const totalBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.set),
  map(() => {
    const currentBets = currentBetsSelector(state$.value);

    return totalBet.set({ value: getTotalBet(currentBets), });
  })
);

const totalsForAllBetsEpic = (action$, state$) => action$.pipe(
  ofType(bet.success), // TODO: decide if we need totals updated on bet.success
  // ofType(round.set),
  // pluck('payload', 'roundStatus'),
  // distinctUntilChanged(),
  // filter(equals(NO_MORE_BETS)),
  flatMap(() => {
    const calcTotalValue = (betTotalSelector, currBetValueSelector) => (
      betTotalSelector(state$.value) + propOr(0, 'value', currBetValueSelector(state$.value))
    );

    const totalsObj = {
      // TOTALS FOR MAIN BETS
      [TOTALS.TOTAL_PLAYER_A]: calcTotalValue(totalPlayerASelector, playerABetSelector),
      [TOTALS.TOTAL_PLAYER_B]: calcTotalValue(totalPlayerBSelector, playerBBetSelector),
      // TOTALS FOR SIDEBETS
      [TOTALS.TOTAL_ONE_PAIR]: calcTotalValue(totalOnePairASelector, onePairBetSelector),
      [TOTALS.TOTAL_THREE_OF_A_KIND]: calcTotalValue(totalThreeOfAKindSelector, threeOfAKindBetSelector),
      [TOTALS.TOTAL_STRAIGHT]: calcTotalValue(totalStraightSelector, straightBetSelector),
      [TOTALS.TOTAL_FLUSH]: calcTotalValue(totalFlushSelector, flushBetSelector),
      [TOTALS.TOTAL_STRAIGHT_FLUSH]: calcTotalValue(totalStraightFlushSelector, straightFlushBetSelector),
    };

    return of(
      myBetsActions.myBets.cache(),
      totals.set(totalsObj),
      bet.clear(),
      totalBet.set({ value: pipe(values, sum)(totalsObj), }),
    );
  }),
);

const betRequestEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload', 'roundStatus'),
  distinctUntilChanged(),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(round.set),
      pluck('payload', 'roundStatus'),
      distinctUntilChanged(),
      filter(equals(PLACE_YOUR_BETS)),
      take(1)
    )
  ),
  map(() => createPlaceBetRequestPayload(state$.value)),
  filter(length),
  flatMap((_actions) => concat(...rMap(of, _actions), of(betActions.bet.success())))
);

const betUndoEpic = (action$, state$) => action$.pipe(
  ofType(history.pop),
  map(() => validateBetUndo(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const rebetEpic = (action$, state$) => action$.pipe(
  ofType(bet.rebet),
  map(() => validateRebet(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const doubleEpic = (action$, state$) => action$.pipe(
  ofType(bet.double),
  map(() => validateDouble(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

export default combineEpics(
  placeBetEpic,
  totalBetEpic,
  betResetEpic,
  betRequestEpic,
  betUndoEpic,
  doubleEpic,
  rebetEpic,
  totalsForAllBetsEpic,
);
