import { combineEpics, ofType, } from 'redux-observable';
import {
  filter, flatMap, ignoreElements, pluck, switchMap, take, tap,
} from 'rxjs/operators';
import { actions, socket$, } from '@ezugi/bootstrap';
import {
  CANCEL_LAST_CARD,
  CANCEL_ROUND,
  CARD,
  GAME_RESULT,
  INITIAL_DATA,
  NO_MORE_BETS,
  PLACE_YOUR_BETS,
  WON_BETS,
} from '@ezugi/constants';
import { Card, } from '@ezugi/primitives';

import handleInitialData from './handleInitialData';
import handleGameResult from './handleGameResult';
import handleCardMessage from './handleCardMessage';
import handleCancelLastCard from './handleCancelLastCard';
import handleWonBets from './handleWonBets';
import handlePlaceYourBets from './handlePlaceYourBets';
import handleCancelRound from './handleCancelRound';
import handleNoMoreBets from './handleNoMoreBets';

const { socketActions: { socket, }, videoActions: { video, }, } = actions;

function initialDataEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap((socketMessage) => handleInitialData(socketMessage, state$.value))
  );
}

function placeYourBetsEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === PLACE_YOUR_BETS),
    flatMap((socketMessage) => handlePlaceYourBets(socketMessage, state$.value))
  );
}

function noMoreBetsEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === NO_MORE_BETS),
    flatMap((socketMessage) => handleNoMoreBets(socketMessage, state$.value))
  );
}

function gameResultEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap((socketMessage) => handleGameResult(socketMessage, state$.value))
  );
}

function cardMessageEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CARD),
    flatMap((socketMessage) => handleCardMessage(socketMessage, state$.value))
  );
}

function cancelLastCardEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CANCEL_LAST_CARD),
    flatMap((socketMessage) => handleCancelLastCard(socketMessage, state$.value))
  );
}

function cancelRoundEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CANCEL_ROUND),
    flatMap((socketMessage) => handleCancelRound(socketMessage, state$.value))
  );
}

function preloadCardEpic(action$) {
  return action$.pipe(
    ofType(video.set),
    take(1),
    pluck('payload', 'cdnList', 'videoDelay'),
    switchMap(
      (delay) => (+delay
        ? socket$.pipe(
          filter(({ MessageType, }) => MessageType === CARD),
          pluck('CardName'),
          tap(Card.preload)
        )
        : socket$.pipe(
          take(1),
          tap(() => {
            Card.preloadAll();
          })
        ))
    ),
    ignoreElements()
  );
}

function wonBetsEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === WON_BETS),
    flatMap((socketMessage) => handleWonBets(socketMessage, state$.value))
  );
}

export default combineEpics(
  gameResultEpic,
  cardMessageEpic,
  preloadCardEpic,
  cancelLastCardEpic,
  cancelRoundEpic,
  initialDataEpic,
  wonBetsEpic,
  placeYourBetsEpic,
  noMoreBetsEpic,
);
