import {
  betsEpic,
  betsReducer,
  cardsEpic,
  cardsReducer,
  myBetsEpic,
  payoutsEpic,
  socketEpic,
  statisticsReducer,
} from './store/index';
import { resultMessageClassName, gameResultsClassName, } from './components/ResultDisplay/style';
import { parentClassName, } from './components/RightSideDrawerToggler/style';
import RoundStepInfo from './components/RoundStepInfo';
import { GAME_BOARD_PERCENTAGE_HEIGHT, } from './views/GameBoard/style';

const noop = () => Promise.resolve({ default: () => null, });

export default () => ({
  components: {
    GameBoard: () => import('./views/GameBoard'),
    Payouts: () => import('./components/Payouts'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    // TODO: Do we need SixCardDialog component in 1DTP?
    // SixCardDialog: () => import('./components/SixCardDialog'),
    RightSideDrawerContent: () => import('./components/RightSideDrawerContent'),
    GameInfo: () => import('./components/GameInfo'),
    ResultDisplay: () => import('./components/ResultDisplay'),
    Statistics: () => import('./components/Statistics'),
    VideoOverlay: () => import('./components/VideoOverlay'),
    MyBets: () => import('./components/MyBets'),
    ProgressBar: noop,
  },
  store: {
    epics: [ socketEpic, cardsEpic, betsEpic, payoutsEpic, myBetsEpic, ],
    reducers: { cards: cardsReducer, bets: betsReducer, statistics: statisticsReducer, },
  },
  props: {
    GameResults: { hideWinnerList: true, className: gameResultsClassName, },
    GameRightSideDrawer: { toggler: {
      component: () => import('./components/RightSideDrawerToggler'),
      parentClassName,
    },
    style: { height: '50%', top: `-${GAME_BOARD_PERCENTAGE_HEIGHT / 2}%`, }, },
    ResultMessage: {
      className: resultMessageClassName,
    },
    BetToolbar: {
      style: {
        zIndex: 1,
      },
      children: RoundStepInfo,
    },
  },
  gameBoardHeight: 22,
  landscapeToolbarOrientation: 'vertical',
  desktopBoardToggle: true,
});
