import { concat, of, } from 'rxjs';
import { ROUND_CANCELLED, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import cardsActions from '../../actions/cards';

const {
  configActions,
  roundActions: { round, },
  betActions: { history, },
} = bootstrapActions;

/* eslint-disable-next-line */
const handleCancelRound = ({ MessageType, MessageID, Code, ErrorCode, ...payload }) => {
  return concat(
    // additional bet.reset() is made by @ezugi/bootstrap
    of(round.set({ roundStatus: ROUND_CANCELLED, })),
    of(history.reset()),
    of(cardsActions.cards.reset()),
    of(configActions.config.set({ payoutsProgress: {}, })),
  );
};

export default handleCancelRound;
