import styled, { css, } from 'react-emotion';
import bowser from 'bowser';
import { theme, } from '@ezugi/bootstrap';
import { SIZES, } from '@ezugi/constants';

const { APPBAR_PORTRAIT_HEIGHT, } = SIZES;

const { palette, } = theme;

export const Line = styled.p`
  order: 0 !important;
  margin: 0;
  padding: 20px 80px;
  opacity: 0.9;
  border-radius: 16px;
  // colored by default for PlayerA the other bets will overwrite this property
  background: linear-gradient(
    270deg,
    rgba(39, 93, 177, 0) 0%,
    rgba(39, 93, 177, 0.72) 17.56%,
    #275db1 51.53%,
    rgba(39, 93, 177, 0.78) 84.82%,
    rgba(39, 93, 177, 0) 100%
  );
  font-family: Roboto;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
  text-align: center;
`;

export const CardsStrength = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 900;
  line-height: 21px;
  text-align: center;
  text-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
`;

export const mobileCardsStrengthClassName = css`
  font-size: 12px;
  line-height: 14px;
`;

export const winnningHandHighlight = css`
  color: ${palette.highlight};
`;

export const playerBClassName = css`
  background: linear-gradient(
    270deg,
    rgba(255, 37, 47, 0) 0%,
    rgba(255, 37, 47, 0.72) 19%,
    #ff252f 51.53%,
    rgba(255, 37, 47, 0.72) 83.67%,
    rgba(255, 37, 47, 0) 100%
  );
`;

export const mobileClassName = css`
  padding: 10px 40px;
  font-size: 16px;
  line-height: 19px;
  text-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.6);
`;

export const pushClassName = css`
  background: linear-gradient(
    270deg,
    rgba(70, 166, 71, 0) 0%,
    rgba(70, 166, 71, 0.72) 19%,
    #46a647 51.53%,
    rgba(70, 166, 71, 0.72) 83.67%,
    rgba(70, 166, 71, 0) 100%
  );
`;

export const WinningSideBets = styled.div``;

export const SideBet = styled.div`
  color: #e7a943;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
  margin: 2px;
  text-transform: uppercase;
`;

export const resultMessageClassName = css`
  > * {
    order: 2;
  }

  > [data-name="win-message"] {
    margin-top: 0;
    order: 1 !important;
  }

  // overwrite bootstrap CSS
  @media all and (orientation: portrait) {
    flex-grow: 1;
    ${bowser.ios ? '' : `padding-top: ${APPBAR_PORTRAIT_HEIGHT}px;`}
  }
`;

export const gameResultsClassName = css`
  @media all and (orientation: portrait) {
    position: relative;
    margin-top: -${APPBAR_PORTRAIT_HEIGHT + 1}px;
  }
  @media screen and (max-height: 680px) and (orientation: portrait) {
    [data-name="winner-list"] {
      max-height: ${bowser.ios ? '25%' : '35%'};
      ${bowser.ios ? 'margin-top: 0px;' : ''}
    }
  }
`;
